.predictor-slider {
    display: flex;
    flex-direction: row;
    background: rgba(0, 113, 24, 0.40);
    height: 76px;
    flex-shrink: 0;
    justify-content: space-around;
    padding: 8px 16px;
    align-items: center;
    width: -webkit-fill-available;
}

.slider-container {
    position: relative;
    width: 510px;
    height: min-content;
    overflow: hidden;
}

.slider {
    display: flex;
    transition: transform 0.3s ease-in-out;
    gap: 20px;
}

.slide {
    display: flex;
    flex: 0 0 150px;
    height: 60px;
    gap: 5px;
    flex-direction: column;
    align-items: center;
    gap: var(--button-border-radius, 4px);
}

.slide .week {
    color: var(--Bela, #FFF);
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.slide .date {
    color: var(--Bela, #FFF);
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.prev,
.next {
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
    z-index: 1;
    display: flex;
    padding: 0px 30px;
}

.prev img,
.next img {
    width: 35px;
}

.slide.active {
    border-bottom: 3px solid var(--Hri-crvena, #D10003);
}

@media (max-width: 375px) {

    .prev,
    .next {
        padding: 0px 15px;
    }
}

@media (max-width: 320px) {

    .prev,
    .next {
        padding: 0px 4px;
    }
}