@import "~react-image-gallery/styles/css/image-gallery.css";
.itemTemplate {
    background: linear-gradient(180deg, #001407 3.61%, #005D1F 69.86%);
    position: relative;
    overflow-y: hidden;
}

.itemTemplate .floating-ball{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
 }

.itemTemplate .limit{
    z-index: 5;
}

.itemTemplate .text {
    margin-bottom: 31px;
}

.itemTemplate .mainImage {
    max-width: 700px;
    height: 400px;
    width: 100%;
    margin-bottom: 40px;
}

.itemTemplate .mainDescription {
    max-width: 550px;
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
}

.itemTemplate video{
    max-width: 700px;
    width: 100%;
    height: 400px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.itemTemplate .podcastQuoute{
    margin-left: 0;
    margin-right: auto;
    margin-top: 40px;
    display: block;
    margin-bottom: 16px;
}

.itemTemplate .image-gallery{
    max-width: 700px;
    width: 100%;
    height: auto;
}
.itemTemplate .image-gallery{
    margin-top: 33px;
    margin-bottom: 50px;
}

@media (max-width: 768px) {
    .itemTemplate .mainImage{
        height: auto;
    }
    .itemTemplate .podcastQuoute{
        margin-top: 0;
    }
}

@media (max-width: 500px) {
    .itemTemplate .backgroundAdd{
        top: 49.5px;
        bottom: auto;
        right: -100px;
    }
}

@media (max-width: 950px) {
    .itemTemplate{
        .cardHolder{
            grid-template-columns: 1fr;
        }
        .floating-ball{
            width: 570px;
            height: 669px;
        }
    }
}

@media (max-width: 600px) {
    .itemTemplate{
        .floating-ball{
            width: 325px;
            height: 334px;
        }
    }
}