.mainPage {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 99px 0 96px;
    box-sizing: border-box;
    background: linear-gradient(180deg, #001407 3.61%, #005D1F 69.86%);
    position: relative;
    overflow-y: hidden;
}

.mainPage .limit {
    /* Limit will not shrink content of this page */
    max-width: 1228px;
    justify-content: flex-start;
    width: 100%;
}

.mainPage .title {
    color: #FFF;
    text-overflow: ellipsis;
    font-family: HeinekenSerif18;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 24px;
}

.mainPage .text {
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    margin-bottom: 69px;
    max-width: 650px;
}

.mainPage .boxBig-holder {
    display: flex;
    gap: 24px;
    width: 100%;
    margin-bottom: 64px;
}

.mainPage .boxBig {
    padding: 60px 24px 24px 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-size: 100% 100% !important;
}

.mainPage .boxBig:first-child {
    background: no-repeat url("../../images/desktopMain1.png");
    background-size: 100% 100%;
}

.mainPage .boxBig:nth-child(2) {
    background: no-repeat url("../../images/desktopMain2.png");
    background-size: 100% 100%;
}

.mainPage .boxBig:last-child {
    background: no-repeat url("../../images/mobilePrizeWinnersBox1.png");
    background-size: 100% 100%;
}

.mainPage .boxBig-holder .title {
    color: #FFF;
    text-overflow: ellipsis;
    font-family: HeinekenSans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.mainPage .boxBig-holder .text {
    color: #FFF;
    text-overflow: ellipsis;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    max-width: 164px;
    display: flex;
    height: max-content;
    margin-bottom: 19px;
}

.mainPage .boxBig-holder .btn {
    padding-top: 8px;
    padding-bottom: 8px;
    color: #000;
    font-family: HeinekenSans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 4px;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    text-decoration: none;
    margin-bottom: 0;
    margin-top: auto;
}

.mainPage .boxBig-holder .btn img {
    width: 20px;
    height: 20px;
    margin-left: 6px;
}

.mainPage .smallBoxHolder {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.mainPage .smallBoxHolder>div:first-child {
    color: #FFF;
    text-overflow: ellipsis;
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 24px;
}

.mainPage .smallBoxHolder>div:last-child {
    display: flex;
    gap: 24px;
}

.mainPage .smallBox {
    display: flex;
    flex-direction: column;
    padding: 20px 16px;
    background: linear-gradient(90deg, rgba(0, 113, 24, 0.90) 4.41%, rgba(0, 93, 31, 0.90) 98.12%);
    flex: 1;
    /* flying */
    box-shadow: 0px 64px 128px 0px rgba(0, 0, 0, 0.03), 0px 32px 64px 0px rgba(0, 0, 0, 0.06), 0px 8px 16px 0px rgba(0, 0, 0, 0.03);
}

.mainPage .smallBox .points-our {
    border-radius: 20px;
    background: #D10003;
    display: flex;
    padding: 9px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #FFF;
    text-edge: cap;
    font-family: HeinekenSans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 24px;
    width: 104px;
    margin-right: 0;
    margin-left: auto;
    box-sizing: content-box;
}

.mainPage .smallBox .title {
    color: #FFF;
    text-overflow: ellipsis;
    font-family: HeinekenSans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 8px;
}

.mainPage .smallBox .text {
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    margin-bottom: 16px;
}

.mainPage .smallBox .link {
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: max-content;
    margin-bottom: 0;
    margin-top: auto;
}


@media (max-width: 1375px) {
    .mainPage .smallBoxHolder {
        margin-bottom: 25px;
    }
}

@media (max-width: 1250px) {
    .mainPage {
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media (max-width: 1150px) {
    .mainPage .title {
        font-size: 36px;
    }

    .mainPage .text {
        font-size: 15px;
        max-width: 600px;
    }

    .mainPage .boxBig {
        padding: 40px 20px 20px 20px;
    }

    .mainPage .boxBig-holder .title {
        font-size: 22px;
        margin-bottom: 8px;
    }

    .mainPage .boxBig-holder .text {
        font-size: 14px;
        margin-bottom: 19px;
    }

    .mainPage .smallBox .points-our {
        border-radius: 15px;
        padding: 7px 14px;
        gap: 8px;
        margin-bottom: 18px;
        width: 84px;
        font-size: 13px;
    }

    .mainPage .smallBox .title {
        font-size: 18px;
    }

    .mainPage .smallBox .text {
        font-size: 14px;
        margin-bottom: 16px;
    }

    .mainPage .smallBox .link {
        font-size: 14px;
    }

    .mainPage .smallBox .link>img {
        width: 20px;
        height: 20px;
    }
}

@media (max-width: 1000px) {
    .mainPage {
        padding-left: 26px;
        padding-right: 26px;
    }
}


@media (max-width: 768px) {
    .mainPage {
        padding: 40px 50px 0 50px;
    }

    .mainPage .text {
        margin-bottom: 24px;
    }

    .mainPage .boxBig-holder {
        flex-direction: column;
        padding-left: 100px;
        padding-right: 100px;
        box-sizing: border-box;
        margin-bottom: 40px;
    }

    .mainPage .boxBig {
        box-sizing: border-box;
        min-height: 255px;
    }

    .mainPage .smallBoxHolder {
        margin-bottom: 50px;
    }

    .mainPage .smallBoxHolder>div:last-child {
        flex-direction: column;
        padding-left: 100px;
        padding-right: 100px;
    }
    .mainPage .smallBoxHolder .smallBox{
        min-height: 196px;
    }
}

@media (max-width: 650px) {
    .mainPage {
        padding: 40px 50px 0 50px;

    }

    .mainPage .boxBig-holder {
        padding-left: 50px;
        padding-right: 50px;
    }

    .mainPage .smallBoxHolder>div:last-child {
        padding-left: 50px;
        padding-right: 50px;
    }

}

@media (max-width: 500px) {
    .mainPage {
        padding: 40px 16px 0 16px;
    }

    .mainPage .title {
        font-size: 32px;
        margin-bottom: 8px;
        max-width: 300px;
    }

    .mainPage .text {
        font-size: 16px;
        margin-bottom: 24px;
        max-width: 285px;
    }


    .mainPage .boxBig-holder {
        padding-left: 0px;
        padding-right: 0px;
    }

    .mainPage .smallBoxHolder>div:last-child {
        padding-left: 0px;
        padding-right: 0px;
    }

    .mainPage .boxBig-holder .title {
        font-size: 24px;
        margin-bottom: 8px;
    }

    .mainPage .boxBig-holder .text {
        font-size: 16px;
        margin-bottom: 32px;
        max-width: 178px;
    }

    .mainPage .boxBig-holder .btn {
        font-size: 14px;
    }

    .mainPage .smallBoxHolder>div:first-child {
        font-size: 24px;
    }

    .mainPage .smallBox {
        padding: 16px;
    }

    .mainPage .smallBox .points-our {
        padding: 9px 16px;
        font-size: 14px;
        margin-bottom: 9px;
    }

    .mainPage .smallBox .title {
        font-size: 20px;
        margin-bottom: 8px;
    }

    .mainPage .smallBox .text {
        font-size: 16px;
        margin-bottom: 16px;
    }

    .mainPage .smallBox .link {
        font-size: 16px;
    }

}