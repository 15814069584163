.accordion {
    max-width: 400px;
    margin: 0 auto;
  }
  
  .accordion-item {
    border-bottom: 1px solid #ccc;
  }
  
  .accordion-item:last-child {
    border-bottom: none;
  }
  
  .accordion-header {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
  }
  
  .accordion-header div:last-child {
    height: 32px;
  }
  
  .accordion-content {
    display: none;
    padding: 10px 0px;
  }
  
  .accordion-item.active .accordion-content {
    display: block;
  }

  .goals {
    width: 17px;
  }
  
  .box-wrapper {
    display: flex;
    gap: 8px;
  }

  .box {
    border-radius: var(--button-border-radius, 4px);
    border: 1px solid var(--Hei-tamno-zelena, #005D1F);
    /* border: none; */
    background: var(--Crna, #000);
    z-index: 2;
    height: 20px;
    color: white;
    padding: 8px 0px;
  }

  .more-fauls-box {
    flex-direction: row;
    text-align: center;
    width: 100%;
    cursor: pointer;
  }

  .more-corners-wrapper {
    flex-direction: column;
  }

  .more-corners-box {
    flex-direction: column;
    text-align: left;
    padding: 8px 8px;
    cursor: pointer;
  }

  .total-goals-box {
    padding: 8px 16px;
    cursor: pointer;
  }

  .active-prediction {
    border: 2px solid var(--Hei-tamno-zelena, red) !important;
  }
  