.signinPage {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 5;
}
.signinPage .btn{
    margin-top: 0;
    color: #FFF;
    border: 2px solid #FFF;
    background: none;
    margin-bottom: 40px;
}
.signinPage .holder{
    margin-bottom: 40px;
}
.signinPage .radioHolder input{
    width: 27.5px !important;
    height: 23px;
}
.signinPage .forgot{
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    text-decoration-line: underline;
    display: flex;
    justify-content: center;
    align-items: center;
}
.signinPage .forgot img{
    margin-left: 6px;
    width: 16px;
    height: 16px;
}
.signinPage .forgot:hover{
    cursor: pointer;
}

@media (max-width: 650px) {
    .signinPage{
        order: 1
    }
}