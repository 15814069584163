.rankingList {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.rankingList .paginate-tab {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;
}

.rankingList .subTitle {
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 33.6px */
    margin-right: 50px;
}

.rankingList .rank-pagination {
    background: rgba(0, 113, 24, 0.40);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 20px;
    box-sizing: border-box;
    gap: 30px;
    min-width: 349px;
}

.rankingList .rank-pagination .month {
    color: #FFF;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 24px */
    height: 60px;
    display: flex;
    align-items: center;
    border: 4px solid transparent;
}

.rankingList .rank-pagination .month:hover {
    cursor: pointer;
}

.rankingList .rank-pagination img:hover {
    cursor: pointer;
}

.rankingList .rank-pagination .isCurrent {
    border-bottom: 4px solid #D10003;
}

.rankingList .prize-name {
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 28px */
    margin-bottom: 16px;
    width: max-content;
    margin-top: 16px;
}

.rankingList .winner-info {
    border-bottom: 1px solid #B0B0B8;
    display: flex;
    color: #FFF;
    max-width: 627px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
    margin-bottom: 8px;
    padding-left: 13px;
    box-sizing: border-box;
}

.rankingList .winner-info .place {
    font-family: HeinekenSans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 25.2px */
    width: 23px;
}

.rankingList .winner-info .name {
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 25.6px */
    letter-spacing: 0.48px;
    margin-left: 10px;
    margin-right: auto;
    display: flex;
}

.rankingList .winner-info .point {
    border-radius: 26px;
    background: #D10003;
    padding: 12px 8px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.rankingList .loadMore {
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    margin-top: 40px;
    margin-right: 16px;
    margin-bottom: 81px;
}
.rankingList .loadMore:hover{
    cursor: pointer;
}

@media (max-width: 1100px) {
    .rankingList .paginate-tab{
        flex-direction: column;   
    }
    .rankingList .paginate-tab .subTitle{
        margin-bottom: 15px;
    }
}

@media (max-width: 500px) {
    .rankingList .paginate-tab{
        align-items: flex-start;
    }
    .rankingList .paginate-tab .rank-pagination{
        width: 100%;
        padding: 12px;
        gap: 10px;
        min-width: 100%;
    }
    .rankingList .prize-name{
        margin-top: 12px;
        margin-bottom: 12px;
        max-width: 241px;
    }
}