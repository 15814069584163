.predictorPage{
    background: linear-gradient(180deg, #001407 3.61%, #005D1F 69.86%);
    position: relative;
    overflow-y: hidden;
    min-height: 1214px;
    height: 100%;
    justify-content: flex-start;
}
.predictorPage .limit{
    z-index: 5;
    min-height: 80vh;
}
.predictorPage{
     .floating-ball{
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
     }
     .floating-grass{
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1;
        height: auto;
     }
}

.predictor-top-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.predictor-top-wrapper .predictor-text {
    display: flex;
}

.predictor-top-wrapper div:last-child {
    display: flex;
    align-items: center;
}

.loading-spinner-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 20;
}

.loading-screen {
    margin-left: 30%;
}

.predictorPage{
    .floating-ball{
       position: absolute;
       top: 0;
       right: 0;
       z-index: 1;
    }
    .floating-grass{
       position: absolute;
       bottom: 0;
       width: 100%;
       z-index: 1;
       height: auto;
    }
    .cardHolder{
       gap: 24px;
       margin-bottom: 76px;
       display: flex;
       flex-direction: row;
    }
}

.predictorPage .clockHold {
    background: #D10003;
    bottom: 16px;
    padding: 19.5px 16px;
    display: flex;
    color: #FFF;
    font-family: "Inter", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    min-width: 194px;
    height: max-content;
}

.predictorPage .clockHold img {
    width: 32px;
    height: 32px;
    margin-right: 15px;
}

.predictorPage .submitClockHold{
    margin-top: 20px;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
}
.predictorPage .submitClockHold:hover{
    cursor: pointer;
}

@media (max-width: 950px) {
   .predictorPage{
       .floating-ball{
           width: 570px;
           height: 669px;
       }
   }
}

@media (max-width: 900px) {
    .predictorPage{
        .cardHolder{
            flex-direction: column;
        }
        .clockHold{
            max-width: max-content;
            min-width: 194px;
        }
    }
 }

@media (max-width: 768px) {
    .cardHolder {
        justify-content: space-between;
        margin-top: 20px;
    }
    
} 

@media (max-width: 600px) {
   .predictorPage{
       .floating-ball{
           width: 325px;
           height: 334px;
       }
   }
}

@media (max-width: 500px) {
    .predictorPage .clockHold {
        padding: 8px;
        font-size: 20px;
        min-width: 125px;
    }
}

@media (max-width: 425px) {
    .predictorPage .cardHolder {
        align-items: center;
    }
    .predictorPage .clockHold{
        max-width: 100%;
        width: 100%;
        min-width: auto;
        justify-content: center;
    }
}
