.popup {
    display: flex;
    width: 361px;
    padding: 16px;
    align-items: flex-start;
    gap: 16px;
    background: var(--Hri-crvena, #D10003);
    position: absolute;
    top: 20px;
    right: 20%;
    z-index: 6;
}

.popup div:first-child img {
    margin-top: 4px;
}

.popup .info {
    color: var(--Bela, #FFF);
    font-family: HeinekenSans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.popup .link {
    color: var(--Bela, #FFF);
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-decoration-line: underline;
    cursor: pointer;
    display: flex;
}

.popup .info .points {
    color: var(--Bela, #FFF);
    font-family: HeinekenSans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

@media (max-width: 450px) {
    .popup {
        width: 280px;
        right: 10px;
    }
}