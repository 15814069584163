.gamesPage {
    min-height: 754px;
    display: flex;
    justify-content: flex-start;
}

.gamesPage .info {
    display: flex;
    justify-content: space-between;
}

.gamesPage .boxBig-holder {
    margin-bottom: 103px;
}

.gamesPage .boxBig-holder .boxBig .text {
    max-width: 220px !important;
}

.gamesPage .boxBig {
    position: relative;
    z-index: 5;
    min-height: 188px;
}

.gamesPage .boxBig:first-child {
    background: no-repeat url("../../images/desktopGamesBox1.png") !important;
    background-position: right !important;
}

.gamesPage .boxBig:last-child {
    background: no-repeat url("../../images/desktopGamesBox2.png") !important;
    background-position: right !important;
}

.gamesPage .boxBig-holder .points {
    color: #FFF;
    text-align: right;
    font-family: HeinekenSans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 99px;
    background: #D10003;
    padding: 9px 16px;
    width: max-content;
    margin-right: 0;
    margin-left: auto;
    margin-bottom: 21px;
    position: absolute;
    top: 24px;
    right: 16.33px;
    z-index: 5;
}

.gamesPage .boxBig-holder .boxBig>img {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 40px;
    right: 105px;
    z-index: 3;
}

@media (max-width:900px) {
    .gamesPage .info {
        flex-direction: column;
    }

    .gamesPage .info .text {
        margin-bottom: 40px;
    }
}

@media (max-width:768px) {

    .gamesPage .boxBig-holder .boxBig {
        width: 400px;
        margin-left: auto;
        margin-right: auto;
        background-size: 100% 100%;
        min-height: 213px;
    }

    .gamesPage .boxBig-holder {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width:500px) {
    .gamesPage .boxBig-holder .boxBig {
        min-height: 242px;
    }

    .gamesPage .boxBig-holder .boxBig .title {
        max-width: 230px;
    }

    .gamesPage .boxBig:first-child {
        background: no-repeat url("../../images/mobileGamesBox1.png") !important;
        background-size: cover !important;
    }
    
    .gamesPage .boxBig:last-child {
        background: no-repeat url("../../images/mobileGamesBox2.png") !important;
        background-size: cover !important;
    }
}

@media (max-width:460px) {
    .gamesPage .boxBig-holder .boxBig {
        width: 100%
    }

    .gamesPage .boxBig-holder {
        padding-left: auto;
        padding-right: auto;
    }
}

@media (max-width:370px) {
    .gamesPage .boxBig-holder .boxBig {
        min-height: 266px;
    }

    .gamesPage .boxBig-holder .boxBig .title {
        max-width: 130px;
    }
    .gamesPage .boxBig:first-child {
        background-position: right !important;
    }
    
    .gamesPage .boxBig:last-child {
        background-position: right !important;
    }
}

@media (max-width:350px) {
    .gamesPage .boxBig-holder .boxBig {
        padding-top: 20px;
        min-height: 278px;
    }

    .gamesPage .boxBig-holder .boxBig .points {
        position: static;
        margin-bottom: 0;
    }
}