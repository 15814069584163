.prizePage {
    background: linear-gradient(180deg, #001407 3.61%, #005D1F 69.86%);
    position: relative;
    overflow-y: hidden;
}

.prizePage .limit {
    z-index: 5;
}

.prizePage .perks {
    background: #00358F;
    padding: 16px;
    box-sizing: border-box;
    width: 611px;
    display: flex;
    gap: 16px;
    margin-bottom: 40px;
}

.prizePage .infoIcon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.prizePage .info {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.prizePage .info div:first-child {
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.prizePage .info div:nth-child(2) {
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 22.4px */
}

.prizePage .info a {
    color: #FFF;
    /* body/base link */
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    text-decoration-line: underline;
    display: flex;
    align-items: center;
    width: max-content;
}

.prizePage .info a img {
    margin-left: 8px;
}

.prizePage .subTitle {
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 33.6px */
    margin-bottom: 16px;
}

.prizePage .line {
    display: flex;
    height: 1px;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
}

.prizePage .prizeHolder {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
}

@media (max-width: 730px) {
    .prizePage .perks {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .prizePage .backgroundAdd {
        top: 49.5px;
        right: 0;
    }
    .prizePage .perks{
        margin-bottom: 24px;
    }
    .prizePage .prizeHolder {
        gap: 40px 10px;
        width: 100%;
        justify-content: center;
    }
}