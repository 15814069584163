.dropdown{
    width: 179px;
    padding-top: 21px;
    padding-bottom: 8px;
    background: #000;
    color: #FFF;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 40px;
    left: 0;
    text-transform: none;
    z-index: 10;
}
.dropdown a{
    padding: 8px 16px;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
}
.dropdown a:hover{
    background: #FFFFFF33;
    cursor: pointer;
}

@media (max-width: 1200px) {
    .dropdown a{
        font-size: 14px; 
    }
    .dropdown{
        padding-top: 23px;
        top: 38px;
    }
}

@media (max-width: 900px) {
    .dropdown{
        position: static;
        padding-top: 0;
        padding-bottom: 0;
    }
    .dropdown a{
        margin-bottom: 0 !important;
    }
}