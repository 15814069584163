/*This component uses already made classes from mainPage.css , and added few modifications*/
.fansPage {
    min-height: 635px;
    display: flex;
    justify-content: flex-start;
}
.fansPage .floating-grass{
    margin-bottom: -94px;
}
.fansPage .boxBig:first-child {
    background: no-repeat url("../../images/desktopFans1.png");
}
.fansPage .boxBig:nth-child(2) {
    background: no-repeat url("../../images/desktopFans2.png");
}
.fansPage .boxBig:nth-child(3) {
    background: no-repeat url("../../images/desktopFans3.png");
}
/*.fansPage .boxBig:last-child {
    background: no-repeat url("../../images/desktopFans4.png");
}*/
.fansPage .text{
    margin-bottom: 40px;
}
.fansPage .boxBig-holder{
    margin-bottom: 100px;
}
.fansPage .boxBig-holder .boxBig{
    padding-top: 60px;
}

.fansPage .points-our {
    border-radius: 20px;
    background: #D10003;
    display: flex;
    padding: 9px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #FFF;
    text-edge: cap;
    font-family: HeinekenSans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 24px;
    width: 104px;
    box-sizing: content-box;
    margin: -35px 0 0 auto;
}

@media (max-width: 1200px) {
    .fansPage .boxBig-holder{
        width: 1100px;
        max-width: 100%;
        flex-wrap: wrap;
    }
    .fansPage .boxBig-holder .boxBig{
        width: 256px ;
    }
}

@media (max-width: 1150px) {
    .fansPage .boxBig-holder .boxBig{
        padding-top: 60px;
    }
    .fansPage .points-our {
        border-radius: 15px;
        padding: 7px 14px;
        gap: 8px;
        width: 84px;
        font-size: 13px;
    }
}

@media (max-width: 1000px) {
    .fansPage > .floating-grass{
        margin-bottom: 0
       }
}

@media (max-width: 768px) {
    .fansPage .boxBig-holder{
        width: 1100px;
        max-width: 100%;
        flex-wrap: wrap;
    }
    .fansPage .boxBig-holder .boxBig{
        width: 100%;
        background-size: 100% 100%;
    }
    .fansPage .boxBig-holder .boxBig .text{
        max-width: 185px;
    }
}

@media (max-width: 500px) {
    .fansPage .points-our {
        padding: 9px 16px;
        font-size: 14px;
    }
}

@media (max-width: 450px) {
    .fansPage .boxBig:first-child {
        background: no-repeat url("../../images/mobileFans1.png");
    }
    .fansPage .boxBig:nth-child(2) {
        background: no-repeat url("../../images/mobileFans2.png");
    }
    .fansPage .boxBig:nth-child(3) {
        background: no-repeat url("../../images/mobileFans3.png");
    }
}

