.cardComponent {
    display: flex;
    max-width: 600px;
    width: 100%;
    
}

.cardComponent>img {
    width: 269px;
    height: 200px;
}

.cardComponent .cardInfo {
    padding: 16px 32px 16px 24px;
    background: #FFF;
    /* flat */
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

.cardComponent .cardInfo>div:first-child {
    color: #000;
    font-family: HeinekenSans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 4px;
}

.cardComponent .cardInfo>div:nth-child(2) {
    color: #000;
    text-overflow: ellipsis;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    margin-bottom: auto;
    margin-top: 0;
    max-width: 251px;
}

.cardComponent .cardInfo>a:last-child {
    color:  #D10003;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    text-decoration-line: underline;
    display: flex;
    align-items: center;
    width: max-content;
}
.cardComponent .cardInfo>a:last-child > img{
    margin-left: 8px;
}
.cardComponent .cardInfo>a:last-child:hover{
    cursor: pointer;
}

@media (max-width: 1150px) {
    .cardComponent>img {
        height: 235px
        
    }
    .cardComponent .cardInfo {
        padding: 8px 16px 8px 12px;
    }
}

@media (max-width: 600px) {
    .cardComponent>img {
        width: 187px !important;
        height: 140px;
        flex-shrink: 0;
    }
    .cardComponent .cardInfo {
        padding: 16px;
        width: 100%;
    }
    .cardComponent .cardInfo>div:nth-child(2) {
        display: none;
    }
}

@media (max-width: 400px) {
   

    .cardComponent .cardInfo>div:first-child {
        font-size: 18px;
    }
    .cardComponent .cardInfo {
        padding: 8px;
    }
    .cardComponent .cardInfo>a:last-child > img{
        margin-left: 0;
    }
}

@media (max-width: 360px) {
    .cardComponent>img {
        width: 150px !important;
        height: 120px;
    }
}