.welcomePage {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 727px;
    height: auto;
    background: url("../../images/HDWelcome.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.welcomePage .welcome-box {
    display: flex;
    flex-direction: column;
    max-width: 529px;
    margin-left: 125px;
    margin-bottom: 136px;
    margin-top: auto;
}

.welcomePage .text1 {
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 6px;
}

.welcomePage .text-main {
    color: #FFF;
    font-family: HeinekenSerif18;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 23px;
    margin-top: 0;
}

.welcomePage .btn {
    display: flex;
    min-height: 48px;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: #FFF;
    text-transform: uppercase;
    font-family: HeinekenSans;
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    text-decoration: none;
    color: #000;
    width: max-content;
    box-sizing: border-box;
}


.welcomePage .btn:hover {
    cursor: pointer;
}

@media (max-width: 1440px) {
    .welcomePage {
        background: url("../../images/desktopWelcome.png");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
}

@media (max-width: 1340px) {
    .welcomePage .welcome-box {
        margin-left: 65px;
    }
}

@media (max-width: 1240px) {
    .welcomePage {
        background: url("../../images/desktopWelcome.png");
        background-size: 100% 650px;
        background-position: unset;
        background-repeat: no-repeat;
        min-height: 650px;
    }
}

@media (max-width: 1160px) {
    .welcomePage {
        background: url("../../images/desktopWelcome.png");
        background-size: 100% 600px;
        background-position: unset;
        background-repeat: no-repeat;
        min-height: 600px;
    }

    .welcomePage .welcome-box {
        margin-bottom: 80px;
    }

    .welcomePage .text1 {
        font-size: 27px;
    }

    .welcomePage .text-main {
        font-size: 41px;
    }

    .welcomePage .btn {
        font-size: 15px;
    }
}

@media (max-width: 1080px) {
    .welcomePage {
        background: url("../../images/desktopWelcome.png");
        background-size: 100% 550px;
        background-position: unset;
        background-repeat: no-repeat;
        min-height: 550px;
    }
}

@media (max-width: 1000px) {
    .welcomePage {
        background: url("../../images/desktopWelcome.png");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .welcomePage .welcome-box {
        margin-left: 35px;
    }
}

@media (max-width: 915px) {
    .welcomePage {
        background: url("../../images/desktopWelcome.png");
        background-size: 100% 450px;
        background-position: unset;
        background-repeat: no-repeat;
        min-height: 450px;
    }

    .welcomePage .welcome-box {
        margin-bottom: 30px;
    }
}

@media (max-width: 600px) {
    .welcomePage {
        background: url("../../images/mobileWelcome.png");
        background-size: 100% 561px;
        background-position: unset;
        background-repeat: no-repeat;
        min-height: 561px;
    }

    .welcomePage .welcome-box {
        height: 100%;
        padding-left: 16px;
        padding-right: 16px;
        margin: 0;
    }

    .welcomePage .welcome-box .text1 {
        margin-top: 40px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        /* 24px */
    }

    .welcomePage .welcome-box .text-main {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        max-width: 294px !important;
        width: 100% !important;
    }

    .welcomePage .welcome-box .btn {
        width: 100%;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 27px;
        margin-top: auto;
    }

}