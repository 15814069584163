.ritualsPage {
    padding-bottom: 100px;
    position: relative;
    overflow-y: hidden;
}

.limit {
    z-index: 2;
}

.ritualsPage .title {
    color: #FFF;
    text-overflow: ellipsis;
    font-family: HeinekenSerif18;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.ritualsPage .ritualText {
    overflow: hidden;
    color: var(--Bela, #FFF);
    text-overflow: ellipsis;
    font-family: HeinekenSans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.6px;
    padding: 22px 16px;
    min-width: 32px;
}

.ritualIndex {
    align-self: baseline;
    color: var(--Bela, #FFF);
    font-family: HeinekenSans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    padding-top: 26px;
}

.listItem {
    border-bottom: 2px solid white;
    padding-bottom: 10px;
}

.listItem:last-child {
    border-bottom: none;
}

.ritual-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ritual {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    min-height: 80px;
    padding: 0px 20px 0px 20px;
}

.voteContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0px 10px 0px;
}

.item-voted {
    background: var(--Hei-ucl-zelena, #49A942);
    opacity: 1;
}

.item-disabled {
    opacity: 0.5 !important;
}

.item-voted div {
    color: var(--Crna, #000) !important;
}

.ritualTextContainer {
    display: flex;
    flex-direction: row;
    max-width: 65%;
}

@media (max-width: 1375px) {
    .mainPage .smallBoxHolder {
        margin-bottom: 25px;
    }

}

@media (max-width: 1250px) {
    .mainPage {
        padding-left: 50px;
        padding-right: 50px;
    }

    .ritualTextContainer {
        max-width: 48%;
    }

}

@media (max-width: 1150px) {
    .mainPage .title {
        font-size: 36px;
    }
    .ritualTextContainer {
        max-width: 58%;
    }
}

@media (max-width: 1024px) {
    .mainPage {
        padding-left: 26px;
        padding-right: 26px;
    }

}


@media (max-width: 768px) {
    .ritualsPage {
        padding: 40px 50px 0 50px;

    }

    .ritualTextContainer {
        max-width: 100%;
    }

    .ritualsPage .voteContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .ritualsPage .voteButton {
        width: 90%;
        margin-right: 0px;
        padding: 16px;
    }

    .voteButton-voted {
        width: 90%;
        margin-right: auto !important;
        margin-left: auto;
        padding: 16px;
    }

    .ritualsPage .progressBarContainer {
        flex-direction: row;
        width: 90%;
        padding: 16px;
    }
}

@media (max-width: 650px) {
    .ritualsPage {
        padding: 40px 16px 0 16px;
    }
}

@media (max-width: 500px) {
    .ritualTextContainer {
        max-width: 100%;
    }

    .listItem {
        padding:unset;
    }

    .ritualsPage .voteContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 20px 0px 20px;
    }

    .ritualsPage .voteButton {
        width: 90%;
        margin-right: 0px;
        padding: 16px;
    }

    .ritualsPage .progressBarContainer {
        flex-direction: row;
        width: 90%;
        padding: 16px;
    }
}