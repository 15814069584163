.predictor-card {
    border-radius: 8px;
    background: linear-gradient(0deg, #000 24.05%, #005D1F 76.8%);
    stroke-width: 2px;
    stroke: var(--Crna, #000);
    height: min-content;
    width: 100%;
    max-width: 294px;
    position: relative;
}

.isPlayedCard{
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(rgba(0, 0, 0, 0.7),
            rgba(0, 0, 0, 0.7));

    top: 0;
    left: 0;
    z-index: 35;
}

.predictor-card .predictor-card-header div {
    color: var(--pure-white, #FFF);
    font-family: HeinekenSans;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    flex: 1 0 0;
}

.predictor-card .predictor-card-header {
    background: #000;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: flex-start;
}

.predictor-card .predictor-card-body .floating-star {
    position: absolute;
}

.predictor-card .predictor-card-body .predict-box {
    border-radius: var(--button-border-radius, 4px);
    border: 1px solid var(--Hei-tamno-zelena, #005D1F);
    background: var(--Crna, #000);
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    z-index: 2;
    height: 20px;
    color: white;
}

.predictor-card .predictor-card-body .score-text {
    color: var(--Bela, #FFF);
    text-align: center;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    padding: 17px 24px 17px 17px;
}

.predictor-card .predictor-card-body .match-box {
    display: flex;
    flex-direction: row;
    color: var(--Bela, #FFF);
    text-align: center;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    padding: 17px 0px 17px 0px;
    justify-content: space-around;
    z-index: 2;
    position: relative;
    padding-top: 60px;
}

.predictor-card .predictor-card-body .score-box {
    display: flex;
    flex-direction: row;
    color: var(--Bela, #FFF);
    text-overflow: ellipsis;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    gap: 8px;
    align-content: center;
    flex-wrap: wrap;
    margin-top: 13px;
    margin-bottom: auto;
}

.predictor-card .predictor-card-body .team-box {
    display: flex;
    flex-direction: column;
    width: 45%;
    min-height: 104px;
}

.predictor-card .predictor-card-body .score-box input {
    width: 17px;
}

.predictor-card .predictor-card-header div:last-child {
    text-align: end;
}

.predictor-card .predictor-card-body .accordion {
    color: rgba(255, 255, 255, 0.80);
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding: 17px 24px 17px 17px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.predict-button-submit {
    border-radius: var(--button-border-radius, 4px);
    background: var(--Hri-crvena, #D10003);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.20);
    display: flex;
    min-height: 40px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    color: white;
    width: 100%;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
}

.predict-button-edit {
    border-radius: var(--button-border-radius, 4px);
    border: 2px solid var(--Bela, #FFF);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.20);
    display: flex;
    min-height: 40px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    color: white;
    width: 100%;
    background-color: #000;
    cursor: pointer;
    box-sizing: border-box;
}

.button-wrapper {
    padding: 17px 24px 17px 17px;
}

@media (max-width: 425px) {
    .predictor-card {
        width: 100%;
    }
}
