.popupList {
    width: 100%;
    max-width: 506px;
}

.popupList .paginate-tab {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    width: max-content;
    height: 94px;
}

.popupList .subTitle {
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 33.6px */
    margin-right: 69px;
}

.popupList {
    .szh-accordion {
        width: 100%;
    }

    .szh-accordion>div {
        width: 100%;
        background: #FFF;
    }
    
    .szh-accordion__item{
        margin-bottom: 16px;
    }

    .szh-accordion button {
        width: 100%;
        padding: 16px;
        color: #007118;
        font-family: HeinekenSans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        /* 25.6px */
        text-decoration-line: underline;
        text-align: left;
        box-sizing: border-box;
    }

    .szh-accordion button:hover {
        cursor: pointer;
    }

    .szh-accordion__item-panel {
        padding: 16px;
        box-sizing: border-box;
    }

    .popup-winner {
        display: flex;
        border-bottom: 1px solid #B0B0B8;
        align-items: center;
        padding-bottom: 8px;
        padding-top: 8px;
    }

    .popup-winner>div:first-child {
        color: #000;
        font-family: HeinekenSans;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        /* 25.2px */
        margin-right: 15px;
        width: 23px;
    }

    .popup-winner>div:last-child {
        color: #2C2C2E;
        font-family: HeinekenSans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        /* 25.6px */
        letter-spacing: 0.48px;
    }
}


@media (max-width: 1100px) {
    .popupList .paginate-tab{
        align-items: flex-start;
    }
}

@media (max-width: 800px) {
    .szh-accordion{
        margin-bottom: 54px;
    }
}

@media (max-width: 500px) {
    .popupList .paginate-tab{
        height: max-content;
        margin-bottom: 40px;
    }
    .rankingList .paginate-tab .rank-pagination{
        width: 100%;
        padding: 12px;
        gap: 10px;
    }
    .rankingList .prize-name{
        margin-top: 12px;
        margin-bottom: 12px;
    }
}