.quizTemplate {
    background: linear-gradient(180deg, #001407 3.61%, #005D1F 69.86%);
    position: relative;
    overflow-y: hidden;
}

.quizTemplate .limit {
    z-index: 5;
}

.backgroundAdd {
    position: absolute;
    top: 49.5px;
    right: 0;
}

.quizTemplate .title {
    margin-bottom: 25px;
    margin-top: 16px;
}

.quizTemplate .imgHold {
    width: 700px;
    height: 394px;
    margin-bottom: 46px;
    position: relative;
}

.quizTemplate .imgHold img {
    width: 100%;
    height: 100%;
}

.quizTemplate .clockHold {
    background: #D10003;
    position: absolute;
    bottom: 16px;
    right: 16px;
    padding: 19.5px 16px;
    display: flex;
    color: #FFF;
    font-family: "Inter", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    min-width: 175px;
}

.quizTemplate .clockHold img {
    width: 32px;
    height: 32px;
    margin-right: 15px;
}

.quizTemplate .question {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    max-width: 700px;
}

.quizTemplate .question div:first-child {
    color: #FFF;
    font-family: "Inter", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 39.2px */
    margin-right: 16px;
}

.quizTemplate .question div:last-child {
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 27px */
}

.quizTemplate .answer {
    padding: 16px;
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.50);
    max-width: 652px;
    box-sizing: border-box;
    margin-bottom: 24px;
}

.quizTemplate .answer[isSelected="true"] {
    border: 2px solid #FFF;
}

.quizTemplate .answer:hover {
    cursor: pointer;
}

.quizTemplate .answer[isDisabled="true"] {
    background: linear-gradient(rgba(0, 0, 0, 0.7),
            rgba(0, 0, 0, 0.7));
            opacity: 0.5;

}

.quizTemplate .answer[isDisabled="true"]:hover {
    cursor: default;
    pointer-events: none;
}

.quizTemplate .progressBar {
    margin-top: 40px;
    margin-bottom: 8px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.50);
    width: 700px;
}

.quizTemplate .progress {
    padding: 4px;
    border-radius: 4px;
    background: #D10003;
}

.quizTemplate .questionNumber {
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 28px */
    margin-bottom: 24px;
    width: 700px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
}

.quizTemplate .submit {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    padding: 8px 32px;
    color: #000;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    margin-bottom: 71px;
    width: max-content;
    margin-left: 600px;
}

.quizTemplate .submit:hover {
    cursor: pointer;
}

.quizTemplate .mobileLogo {
    display: none;
}

@media (max-width: 1350px) {
    .backgroundAdd {
        width: 400px;
        height: 1351px;
    }
}

@media (max-width: 1150px) {
    .backgroundAdd {
        width: 330px;
        height: 1114px;
    }

    .quizTemplate .limit>div {
        max-width: 600px;
    }

    .quizTemplate .submit {
        margin-left: 500px;
    }
}

@media (max-width: 950px) {
    .backgroundAdd {
        width: 270px;
        height: 911px;
    }

    .quizTemplate .limit>div {
        max-width: 600px;
    }

    .quizTemplate .submit {
        margin-left: 500px;
    }
}

@media (max-width: 900px) {
    .backgroundAdd {
        bottom: -450px;
        top: auto;
    }
    
}

@media (max-width: 720px) {
    .backgroundAdd {
        width: 270px;
        height: 911px;
    }

    .quizTemplate .limit>div {
        max-width: 100%;
        width: 100%;
    }

    .quizTemplate .submit {
        margin-left: 500px;
    }

    .quizTemplate .submit {
        width: max-content !important;
        margin-left: auto;
    }

}

@media (max-width: 500px) {
    .quizTemplate .mobileLogo {
        display: inline;
        position: absolute;
        top: 0;
        right: 0;
    }

    .quizTemplate .title {
        margin-top: 32px;
        margin-bottom: 16px;
    }

    .quizTemplate .imgHold {
        height: 251px;
        margin-bottom: 40px;
    }

    .quizTemplate .clockHold {
        padding: 8px;
        font-size: 20px;
        min-width: 125px;
    }

    .quizTemplate .question {
        align-items: flex-start;
    }

    .quizTemplate .question div:first-child {
        font-size: 20px;
    }

    .quizTemplate .question div:last-child {
        font-size: 16px;
    }

}

@media (max-width: 400px) {
    .quizTemplate .imgHold {
        height: 201px;
    }
}

@media (max-width: 350px) {
    .quizTemplate .imgHold {
        height: 170px;
    }
}