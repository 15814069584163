.header-our {
    display: flex;
    padding: 8px 64px;
    justify-content: flex-end;
    align-items: center;
    background: #000;
    color: #fff;
    box-sizing: border-box;
    max-width: 1920px;
    height: 64px;
    z-index: 20;
    position: static;
}

.header-our>img:first-child {
    margin-left: 16px;
    margin-right: auto;
}

.header-our .container-our {
    display: flex;
    align-items: center;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    text-transform: uppercase;
}

.header-our .list-our {
    display: flex;
    gap: 40px;
}

.header-our .list-our>a:nth-child(2),
.header-our .list-our>a:nth-child(3),
.header-our .list-our>a:nth-child(4) {
    position: relative;
    margin-right: 13px;
}

.header-our .list-our>a:nth-child(2)::after,
.header-our .list-our>a:nth-child(3)::after,
.header-our .list-our>a:nth-child(4)::after {
    content: url("../../images/chevronDownWhite.svg");
    position: absolute;
    right: -16px;
    width: 20px;
    height: 100%;
    top: 9px;
    stroke: #fff !important;
}

.header-our .list-our>a:nth-child(2).active::after,
.header-our .list-our>a:nth-child(3).active::after,
.header-our .list-our>a:nth-child(4).active::after {
    content: url("../../images/chevronDownGreen.svg");
}

.header-our a {
    text-decoration: none;
    color: #fff;
    padding: 8px;
}

.header-our a.active {
    color: #49A942;
    border-bottom: 2px solid #49A942;
}

.header-our a[isProfile="true"].active {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.20);
    border: none;
    color: #FFF;
}

.header-our .logout-list {
    margin-left: 24px;
    display: flex;
    align-items: center;
}

.header-our .line {
    width: 1px;
    background: #B0B0B8;
    margin-left: 16px;
    margin-right: 16px;
    height: 48px;
}

.header-our .btn {
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    text-transform: uppercase;
    padding: 8px;
    display: flex;
    align-items: center;
}

.header-our .btn:hover {
    cursor: pointer;
}

.header-our .btn img {
    margin-right: 8px;
}

.header-our .logout-list a {
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
}

.header-our .logout-list a.active {
    padding-top: 10px;
}

.header-our .logout-list .points {
    background: #D10003;
    color: #fff;
    border-radius: 26px;
    padding: 8px;
    font-family: HeinekenSans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: none;
    margin-left: 8px;
    box-sizing: border-box;
}

.header-our>.mobileHeader-our {
    display: none;
}



@media (max-width: 1270px) {
    .header-our {
        padding: 8px 34px;
    }
}

@media (max-width: 1220px) {
    .header-our .container-our {
        font-size: 14px;
    }

    .header-our .list-our {
        gap: 20px;
    }

    .header-our .logout-list {
        margin-left: 12px;
    }

    .header-our .logout-list a {
        padding-left: 8px;
        padding-right: 8px;
        font-size: 14px;

    }

    .header-our .logout-list .points {
        font-size: 13px;
        padding: 6px;
    }

    .header-our .btn {
        font-size: 14px;
    }


}

@media (max-width: 1020px) {
    .header-our {
        padding: 8px 10px;
    }

    .header-our .list-our {
        gap: 10px;
    }
}

@media (max-width: 930px) {
    .header-our {
        order: 2;
        padding: 16px 24px;
        flex-direction: column;
        height: auto;
        position: -webkit-sticky; /* Safari */
        position: sticky;
        bottom: 0;
        width: 100%;
    }

    .header-our>img:first-child {
        display: none;
    }

    .header-our .container-our {
        display: none;
    }

    .header-our .logout-list {
        width: 100%;
        margin-left: 0;
        justify-content: space-between;
        font-family: HeinekenSans;
    }

    .header-our .btn {
        margin-bottom: 29px;
        font-size: 16px;
    }

    .header-our>.mobileHeader-our {
        display: flex;
    }

    .header-our .burger:hover {
        cursor: pointer;
    }

    .header-our .list-our {
        flex-direction: column;
        width: 100%;
        font-size: 16px;
    }

    .header-our .list-our>a {
        width: max-content;
    }

    .header-our .list-our .line {
        width: 100%;
        height: 1px;
        margin: 0 0 32px 0;
    }

    .header-our .logout-list a {
        font-size: 16px;
    }

    .header-our .logout-list .points {
        font-size: 14px;
    }

    .header-our a.active {
        color: #49A942;
        border: none;
        margin-bottom: 0 !important;

    }

    .header-our .list-our>a {
        color: #fff;
    }

    .mobileHeader-expander{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    .expandIcon{
        width: 27.75px;
        height: 27.75px;
    }
    .expandIcon:hover{
        cursor: pointer;
    }

    .arrow {
        position: relative;
    }

    .arrow::after {
        content: none !important;
        position: absolute;
        right: -16px;
        width: 20px;
        height: 100%;
        top: 9px;
        stroke: #fff !important;
    }

    .chevronUp::after {
        content: none !important;
    }





}

@media (max-width: 350px) {
    .header-our {
        padding-left: 5px;
        padding-right: 5px;
    }
}