.App {
  display: flex;
  justify-content: center;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 90px;
}

.App *{
  box-sizing: content-box ;
}

.App .floatingStar {
  position: absolute;
  top: -155px;
  right: 0;
  z-index: 1;
}

.App .floating-grass{
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: auto;
  max-height: 475px;
}

.App .limit{
  z-index: 5;
}

.App .floatingStar-mobile {
  display: none;
}

@media (max-width:1023px) {
  .App{
    margin-top: 54px;
  }
}

@media (max-width: 950px) {
  .App .floatingStar {
      width: 470px;
      height: 420px;
      top: -100px;
  }
}

@media (max-width: 600px) {
  .App .floatingStar {
      display: none;
  }

  .App {
      .floatingStar-mobile {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 2;
          display: inline;
      }
  }

}


