.quizPage {
    min-height: 611px;
    display: flex;
    justify-content: flex-start;
}
.quizPage .floating-grass{
    margin-bottom: -94px;
}

.quizPage .lightGray50 {
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(rgba(0, 0, 0, 0.7),
            rgba(0, 0, 0, 0.7));

    top: 0;
    left: 0;
    z-index: 55;
}

.quizPage .boxBig {
    min-height: 188px;
    
    justify-content: space-between;
    max-width: 600px;
}

.quizPage .boxBig .text {
    margin-bottom: auto;
}

.quizPage .boxBig-holder .points {
    padding: 7.5px 6px !important;
}

.quizPage .boxBig a[isPlayed="true"] {
    pointer-events: none;
    cursor: default;
}

.gamesPage .boxBig-holder .boxBig>img {
    right: 70px;
}

.quizPage .boxBig:first-child {
    background: no-repeat url("../../../images/quizBox1.png") !important;
    background-position: right !important;
}

.quizPage .boxBig:last-child {
    background: no-repeat url("../../../images/quizBox2.png") !important;
    background-position: right !important;
}

@media (max-width: 1000px) {
    .quizPage > .floating-grass{
        margin-bottom: 0
       }
}

@media (max-width:500px) {
    .quizPage .boxBig:first-child {
        background: no-repeat url("../../../images/mobileQuizBox1.png") !important;
        background-size: cover !important;
    } 
    .quizPage .boxBig:last-child {
        background: no-repeat url("../../../images/mobileQuizBox2.png") !important;
        background-size: cover !important;
    }
}

@media (max-width: 768px) {

    .quizPage .boxBig {
        min-height: auto;
    }

    .quizPage .boxBig .text {
        margin-bottom: 32px;
    }
}

@media (max-width:390px) {
    .quizPage .boxBig{
        padding-top: 80px;
    }
}

@media (max-width:370px) {
    .quizPage .boxBig:first-child {
        background-position: right !important;
    }
    
    .quizPage .boxBig:last-child {
        background-position: right !important;
    }
}

@media (max-width:350px) {
    .quizPage .boxBig{
        padding-top: 40px !important;
    }
}