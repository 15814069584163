.progressBarContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--Hei-ucl-zelena, #49A942);
    color: var(--Hei-ucl-zelena, #49A942);
    font-family: "Inter", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    width: 112px;
}

.progressBarContainer .progressText {
    color: var(--Hei-ucl-zelena, #49A942);
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: HeinekenSans;
    padding: 22px 16px;
}

.progressBarContainer .votedText {
    color: #D10003 !important;
}