.resetPass .btns {
    display: flex;
    justify-content: space-between;
    margin-top: 58px;
    width: 100%;
}
.resetPass a{
    text-decoration: none;
}

.resetPass .back {
    background: #FFF;
    color: #000;
}


@media(max-width:650px) {
    .resetPass .btns {
        max-width: 368px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 40px;
    }

    .resetPass .btn {
        padding: 8px 20px;
        margin: 0;
    }
}

@media(max-width:650px) {
    .resetPass .btns {
        max-width: 100%;
    }
}