.myProfile {
    background: linear-gradient(180deg, #001407 3.61%, #005D1F 69.86%);
    position: relative;
    overflow-y: hidden;
    min-height: 100vh;
}

.myProfile .limit {
    z-index: 5;
}
.myProfile .loadingRank{
    max-width: 100px;
    max-height: 100px;
    margin-bottom: 10px;
}

.myProfile .flex {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 64px;
}

.myProfile .text {
    max-width: 480px;
}

.myProfile .flex .title {
    margin-bottom: 0;
}

.myProfile .pointsCircle {
    width: 102px !important;
    height: 102px !important;
    border-radius: 50%;
    background: #D10003;
    display: flex;
    flex-shrink: initial;
    align-items: center;
    justify-content: center;
    color: #FFF;
    text-align: center;
    flex-direction: column;
    margin-right: 24px;
    position: relative;
    flex-shrink: 0;
}

.myProfile .pointsCircle>div:first-child {
    font-family: HeinekenSans;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.myProfile .pointsCircle>div:last-child {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 8px;
}

.myProfile .pointsCircle .infoAbsolute {
    position: absolute;
    top: 0;
    right: 0;
    background: #00358F;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.myProfile .pointsCircle .infoAbsolute:hover{
    cursor: pointer;
}

.myProfile .rectangle-absolute {
    position: absolute;
    background: #00358F;
    /* flying */
    box-shadow: 0px 64px 128px 0px rgba(0, 0, 0, 0.03), 0px 32px 64px 0px rgba(0, 0, 0, 0.06), 0px 8px 16px 0px rgba(0, 0, 0, 0.03);
    padding: 16px;
    display: flex;
    left: calc(100% - 20px);
    top: -40px;
    width: 624px;
}

.myProfile .rectangle-absolute>img {
    margin-right: 16px;
    border: 1px solid #fff;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    padding: 5px;
}

.myProfile .rectangle-absolute .infoText {
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 22.4px */
    margin-bottom: 8px;
}

.myProfile .rectangle-absolute a {
    color:  #FFF;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    text-decoration-line: underline;
    display: flex;
    align-items: center;
    width: max-content;
}
.myProfile .rectangle-absolute a > img{
    margin-left: 8px;
    width: 16px;
    height: 16px;
}

.myProfile .rectangle-absolute .closeI{
    border: none;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 8px;
    top: 8px;
    margin-right: 0;
    padding: 0;
}
.myProfile .rectangle-absolute .closeI:hover{
    cursor: pointer;
}

.myProfile .subTitle {
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 28px */
    margin-bottom: 16px;
}

.myProfile .infoHold {
    position: relative;
    padding: 16px;
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.50);
    max-width: 652px;
    box-sizing: border-box;
    margin-bottom: 24px;
    max-width: 400px;
}

.myProfile .infoHold svg {
    position: absolute;
    right: 16px;
    width: 24px;
    height: 24px;
    top: 16px;
}

.myProfile .infoHold span {
    position: absolute;
    right: 55px;
    width: 24px;
    height: 24px;
    top: 16px;
    color: #FFF;
    font-size: 14px;
    font-family: HeinekenSans;
    font-weight: 400;
    line-height: 150%;
}

.myProfile .infoHold svg:hover {
    cursor: pointer;
}

.myProfile .greenList {
    background: #005D1F;
    display: flex;
    gap: 24px;
    justify-content: space-between;
    padding: 8px 16px;
    margin-bottom: 4px;
    max-width: 612px;
}

.myProfile .greenList[isMe="true"] {
    background-color: #49A942;
}

.myProfile .greenList>div {
    width: 100%;
    font-family: HeinekenSans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 22.4px */
}

.myProfile .lastGreenList {
    margin-bottom: 64px;
}

.myProfile .codeDescription {
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 19.2px */
    margin-top: -20px;
    max-width: 400px;
    background: none;
}

.line {
    background: #B0B0B8;
    width: 712px;
    height: 1px;
    margin-top: 64px;
    margin-bottom: 64px;
}

@media (max-width: 1080px) {
    .line {
        max-width: 100%;
    }
}

@media (max-width: 500px) {
    .myProfile {

        .limit {
            margin-top: 32px;
        }

        .flex {
            margin-bottom: 40px;
        }

        .lastGreenList {
            margin-bottom: 40px;
        }

        .line {
            margin-top: 24px;
            margin-bottom: 24px;
        }
    }
}