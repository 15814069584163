.winnersPage {
    background: linear-gradient(180deg, #001407 3.61%, #005D1F 69.86%);
    position: relative;
    overflow-y: hidden;
    min-height: 729px;
    justify-content: flex-start;
}

.winnersPage .floatingStar {
    position: absolute;
    top: -155px;
    right: 0;
    z-index: 1;
}

.winnersPage .limit {
    z-index: 5;
}

.winnersPage .ranking-holder {
    display: flex;
    justify-content: space-between;
    gap: 100px;
}

.winnersPage .onlyMobile {
    display: none;
}

.winnersPage .ranking-btn {
    border-radius: 4px;
    background: #FBFBFC;
    color: #000;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 18px;
    padding-bottom: 18px;
    max-width: 350px;
    width: 100%;
}

.winnersPage .ranking-btn[isActive="true"] {
    background: #012508;
    /* medium */
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.03), 0px 8px 16px 0px rgba(0, 0, 0, 0.06);
    border: 1px solid #FFF;
    color: #FFF;
}

.winnersPage .ranking-btn:hover {
    cursor: pointer;
}

.winnersPage .floatingStar-mobile {
    display: none;
}

@media (max-width: 900px) {
    .winnersPage .ranking-holder {
        gap: 30px;
    }
}

@media (max-width: 800px) {
    .winnersPage .onlyDesktop {
        display: none
    }

    .winnersPage .onlyMobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0
    }

    .winnersPage .paginate-tab .subTitle {
        margin-right: 0;
    }

}

@media (max-width: 600px) {
    .winnersPage .floatingStar {
        display: none;
    }

    .winnersPage {
        .floatingStar-mobile {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
            display: inline;
        }
    }

}