.mainTemplate {
    background: linear-gradient(180deg, #001407 3.61%, #005D1F 69.86%);
    position: relative;
    overflow-y: hidden;
    min-height: 1214px;
    justify-content: flex-start;
}

.mainTemplate .limit {
    z-index: 5;
    min-height: 80vh;
}


.mainTemplate .floating-ball {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

.mainTemplate .floating-grass {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
    height: auto;
}

.mainTemplate .cardHolder {
    gap: 24px;
    margin-bottom: 76px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}


@media (max-width: 950px) {
    .mainTemplate .cardHolder {
        grid-template-columns: 1fr;
    }

    .mainTemplate .floating-ball {
        width: 570px;
        height: 669px;
        position: absolute;
        top: 0;
        right: 0;
    }

}

@media (max-width: 600px) {
    .mainTemplate .floating-ball {
        width: 325px;
        height: 334px;
        position: absolute;
        top: 0;
        right: 0;
    }

}