.gamesPage {
    .notification {
        width: 400px;
    }

    .quizReady {
        background-size: cover;
        background: url("../../images/gamesNotificationBck.png"), #FFF;
        background-repeat: no-repeat;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.20);
        margin-bottom: 46px;
        box-sizing: border-box;
        padding: 24px 16.33px 24px 24px;
        z-index: 15;
    }

    .quizReady .points {
        color: #FFF;
        text-align: right;
        font-family: HeinekenSans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-radius: 99px;
        background: #D10003;
        padding: 7.5px 6px;
        width: max-content;
        margin-right: 0;
        margin-left: auto;
        position: relative;
        margin-bottom: 21px;

    }

    .quizReady img {
        width: 32px;
        height: 32px;
        position: absolute;
        bottom: -15px;
        left: -15px;
        z-index: -5;
    }

    .quizReady .title {
        color: #000;
        font-family: HeinekenSans;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 6px;
    }

    .quizReady .text {
        color: #000;
        font-family: HeinekenSans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        /* 24px */
        margin-bottom: 6px;
    }

    .quizReady .link {
        color: #D10003;
        font-family: HeinekenSans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        width: max-content;
    }

    .quizReady .link:hover {
        cursor: pointer;
    }


    .quizWaiting {
        background: rgba(255, 255, 255, 0.50);
        padding: 24px 20px 24px 16px;
        box-sizing: border-box;
        margin-bottom: 40px;
    }

    .quizWaiting div:first-child {
        display: flex;
        margin-bottom: 16px;
    }

    .quizWaiting .time {
        color: #012508;
        font-family: HeinekenSans;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: 12px;
    }

    .quizWaiting .text {
        color: #012508;
        font-family: HeinekenSans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        /* 24px */
        margin-bottom: 0;
    }

}

@media (max-width:768px) {
    .gamesPage .notification {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width:460px) {
    .gamesPage .notification {
        width: 100%;
    }
}