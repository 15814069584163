.prizeBox {
    width: 2;
    color: #FFF;
    display: flex;
    flex-direction: column;
}

.prizeBox div:first-child {
    font-family: HeinekenSans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 25.2px */
}

.prizeBox div:nth-child(2) {
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 22.4px */
    margin-bottom: 8px;
}
.prizeBox img{
    width: 262px;
    height: auto;
}

.prizePage > div:last-child{
    margin-bottom: 106px;
}

@media (max-width: 500px) {
    
    .prizeBox{
        max-width: 170px;
    }
    .prizeBox img{
        max-width: 170px;
    }
    .prizeBox div:first-child {
        height: 100%;
    }
    .prizeBox[isMain="true"]{
        max-width: 100% ;
        width: 100% ;
    }
    .prizeBox[isMain="true"] > img {
        width: 100%;
        height: auto;
        max-width: 100%;
    }
}