.articleCard {
    display: flex;
    flex-direction: column;
    margin-bottom: 79px;
    margin-top: 64px;
}

.articleCard .title {
    color: #FFF;
    font-family: "Inter", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 8px;
}

.articleCard .text {
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    margin-bottom: 39px;
}
.articleCard .articleHolder{
    display: flex;
    gap: 20px;
    align-items: center;
    z-index: 5;
    margin-left: -84px;
}

.articleCard .articleHolder > img{
    width: 64px;
    height: 64px;
}

.articleCard .articleHolder > img:hover{
    cursor: pointer;
}

.articleCard .cardComponent{
    flex-direction: column;
    max-width: 244px;
    height: 100%;
}
.articleCard .cardComponent > img{
    max-width: 244px;
}

@media (max-width: 600px) {
    .articleCard .cardComponent > img{
        width: 100% !important;
    }
    .articleCard .cardComponent .cardInfo{
       box-sizing: border-box;
    }
}

@media (max-width: 500px) {
    .articleCard li{
        max-width: 280px;
    }
    .articleCard button{
        display: none;
    }
}

