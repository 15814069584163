.prizeWinners{
    min-height: 611px;
}
.prizeWinners > .floating-grass{
 margin-bottom: -94px;
}
.prizeWinners .boxBig:first-child {
    background: no-repeat url("../../images/prizeWinnersBox1.png") !important;
    background-position: right !important;
}

.prizeWinners .boxBig:last-child {
    background: no-repeat url("../../images/prizeWinnersBox2.png") !important;
    background-position: right !important;
}

@media (max-width:500px) {
    .prizeWinners .boxBig:first-child {
        background: no-repeat url("../../images/mobilePrizeWinnersBox1.png") !important;
        background-size: cover !important;
    } 
    .prizeWinners .boxBig:last-child {
        background: no-repeat url("../../images/mobilePrizeWinnersBox2.png") !important;
        background-size: cover !important;
    }
}

@media (max-width: 1000px) {
    .prizeWinners > .floating-grass{
        margin-bottom: 0
       }
}

@media (max-width:390px) {
    .prizeWinners .boxBig{
        padding-top: 80px;
    }
}

@media (max-width:370px) {
    .prizeWinners .boxBig:first-child {
        background-position: right !important;
    }
    
    .prizeWinners .boxBig:last-child {
        background-position: right !important;
    }
}

@media (max-width:350px) {
    .prizeWinners .boxBig{
        padding-top: 40px !important;
    }
}