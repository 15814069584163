.voteButton {
    min-width: 97px;
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: var(--button-border-radius, 4px);
    background: var(--Bela, #FFF);
    margin-right: 80px;
    cursor: pointer;
}

.voteButton div {
    display: flex;
    flex-direction: row;
}

.voteButton-voted {
    cursor: not-allowed;
    min-width: 97px;
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: var(--button-border-radius, 4px);
    background: var(--Bela, #FFF);
    margin-right: 80px;
    border-radius: var(--button-border-radius, 4px);
    /* border: 2px solid #FFF; */
    background: var(--Hei-tamno-zelena, #D10003);
}

.vote-text {
    color: var(--pure-black, #000);
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.voteButton-voted div {
    display: flex;
    flex-direction: row;
}
