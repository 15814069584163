.signupPage {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 5;
}
.signupPage .radioHolder a{
    color: #FFF;
}

@media (max-width: 650px) {
    .signupPage{
        order: 3;
        margin-bottom: 30px;
    }
}