.inputHolder {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 4px;
    position: relative;
}

.inputHolder:last-child {
    margin-bottom: 40px;
}

.inputHolder input {
    border: none;
    background: rgba(0, 0, 0, 0.50);
    padding: 8px 16px;
    overflow: hidden;
    color: #FFF;
    text-overflow: ellipsis;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    margin-bottom: 23.19px;
    box-sizing: border-box;
}

.inputHolder .err {
    margin-top: 4px;
    color: red;
    font-family: HeinekenSans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 19.2px */
}

.inputHolder .err::before {
    display: inline;
    content: "⚠ ";
}

.inputHolder:has(.err) input {
   margin-bottom: 0 !important;
}

.inputHolder .prefix {
    position: absolute;
    left: 16px;
    top: 0;
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding-top: 8px;
    padding-bottom: 8px;
    height: 40px;
    box-sizing: border-box;
  }

  .inputHolder .infoIcon{
    position: absolute;
    right: 16px;
    background: #00358F;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    padding: 5px;
    border: 1px solid #FFF;
    color: #fff;
    top: 8px;
  }
  .inputHolder .infoIcon:hover{
    cursor: pointer;
  }
  
  .inputHolder input[has-prefix="true"] {
    padding-left: 60px;
  }

  .inputHolder .info-text{
    background: #00358F;
    padding: 10px;
    color: #fff;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    position: absolute;
    right: 16px;
    top: 30px;
  }

  @media (max-width: 1150px) {
    .inputHolder .prefix {
        font-size: 15px;
    }
    .inputHolder input[has-prefix="true"] {
        padding-left: 55px;
      }
  }

  @media (max-width: 500px) {
    .inputHolder .prefix {
        font-size: 16px;
    }
  }