.authPage {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: linear-gradient(180deg, #001407 3.61%, #005D1F 69.86%);
    position: relative;
    overflow-y: hidden;
    background-size: cover;
    padding-top: 100px;
}

.authPage > .floatingStar{
    top: -100px;
}

.authPage>div {
    max-width: 368px;
}

.authPage .line {
    width: 1px;
    background: #B0B0B8;
    margin-left: 134px;
    margin-right: 134px;
    height: 551px;
}

.authPage .title {
    color: #FFF;
    text-overflow: ellipsis;
    font-family: HeinekenSerif18;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 8px;
}

.authPage .text {
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    margin-bottom: 40px;
    min-height: 72px;
}

.authPage .holder {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 64px;
}

.authPage .radioHolder {
    display: flex;
    margin-bottom: 16px;
}

.authPage .radioHolder input {
    width: 23px;
    height: 23px;
    margin: 0;
    background: rgba(0, 0, 0, 0.50);
}

.authPage .radioHolder label {
    color: #FFF;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    margin-left: 14px;
    width: 100%;
}

.authPage .btn {
    display: flex;
    min-height: 48px;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: #FFF;
    color: #000;
    font-family: HeinekenSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 24px */
    text-transform: uppercase;
    margin-bottom: 120px;
    box-sizing: border-box;
}

.authPage .btn:hover {
    cursor: pointer;
}


@media (max-width: 1150px) {
    .authPage .line {
        margin-left: 70px;
        margin-right: 70px;
    }

    .authPage .title {
        font-size: 36px;
    }

    .authPage .text,
    .authPage .radioHolder label,
    .authPage .inputHolder input,
    .authPage .btn,
    .authPage .forgot {
        font-size: 15px;
    }
}

@media (max-width: 950px) {
    .authPage .line {
        margin-left: 25px;
        margin-right: 25px;
    }
}

@media (max-width: 850px) {
    .authPage {
        padding-left: 25px;
        padding-right: 25px;
    }
}

@media (max-width: 650px) {
    .authPage {
        flex-direction: column;
        align-items: center;
        padding-top: 40px;
    }
    .authPage .text {
        min-height: auto;
    }

    .authPage>div {
        max-width: 100%;
        width: 100%;
    }

    .authPage .inputHolder {
        max-width: 368px;
        margin-left: auto;
        margin-right: auto;
    }

    .authPage .holder {
        max-width: 368px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    .authPage .line {
        width: 100%;
        height: 1px;
        margin: 40px 0;
        order: 2;
    }

    .authPage .signupPage .btn {
        margin-bottom: 0;
    }

    .authPage .btn {
        margin-left: auto;
        margin-right: auto;
    }

    .authPage .forgot {
        margin: 0 auto 70px auto;
    }
}


@media (max-width: 500px) {
    .authPage {
        padding-left: 16px;
        padding-right: 16px;
    }

    .authPage .title {
        font-size: 32px;
        margin-bottom: 8px;
    }

    .authPage .text {
        font-size: 16px;
        margin-bottom: 24px;
    }

    .authPage .inputHolder {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        max-width: 100%;
    }

    .authPage .holder {
        margin: 24px 0 !important;
        width: 100%;
        max-width: 100%;
    }

    .authPage .btn {
        margin-left: 0;
    }

    .authPage .forgot {
        margin-left: 0;
    }

    .authPage .radioHolder label,
    .authPage .inputHolder input,
    .authPage .btn,
    .authPage .forgot {
        font-size: 16px;
    }

    .authPage .signinPage .btn {
        margin-bottom: 24px;
    }

}