
.flex-order{
    width: 100%;
}
        /*This will put header at the bottom of page under 900px because header order is set to 2 on this media query in header.css*/
@media (max-width: 930px) {
    .flex-order{
        display: flex;
        flex-direction: column;
        position: relative;
    }
}