.rulesPage {
   display: flex;
   justify-content: center;
   background: linear-gradient(180deg, #001407 3.61%, #005D1F 69.86%);
   padding-top: 20px;
   padding-bottom: 20px;
   min-height: 100vh;
}

.rulesPage .loadingPDF {
   color: #FFF;
   font-family: HeinekenSans;
   font-size: 22px;
   font-style: normal;
   font-weight: 400;
   line-height: 150%;
}


.rulesPage .MediumPDF {
   display: none;
}

.rulesPage .SmallPDF {
   display: none;
}

.rulesPage .ExtraSmallPDF {
   display: none;
}


@media (max-width: 1130px) {

   .rulesPage .MediumPDF {
      display: flex;
      flex-direction: column;
   }

   .rulesPage .LargePDF {
      display: none;
   }

}

@media (max-width: 830px) {

   .rulesPage .SmallPDF {
      display: flex;
      flex-direction: column;
   }

   .rulesPage .MediumPDF {
      display: none;
   }

}

@media (max-width: 640px) {

   .rulesPage .SmallPDF {
      display: none;
   }

   .rulesPage .ExtraSmallPDF {
      display: flex;
      flex-direction: column;
   }


   .rulesPage .loadingPDF {
      font-size: 18px;
   }
}